<!--
 * @Author: flf flf@youlove.com.cn
 * @Date: 2022-11-08 15:52:02
 * @LastEditors: flf flf@youlove.com.cn
 * @LastEditTime: 2022-11-11 10:28:36
 * @FilePath: \wap\src\views\zzy\zyzz.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
    </div>
</template>
<script>

export default {
    created() {
        setTimeout(() => {
            window.open(
                'https://mp.weixin.qq.com/mp/appmsgalbum?__biz=Mzk0NjM2MTIzNQ==&action=getalbum&album_id=2413875234761129986&scene=173&from_msgid=2247483858&from_itemidx=1&count=3&nolastread=1#wechat_redirect',
                '_blank'
            );
        }, 200)

    }
}
</script>